import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  handleChange(event) {
    $.ajax({
      url: '/settings/settings_update',
      type: 'PATCH',
      data: { setting: {value: event.target.value, setting_id: this.element.id} }
    });
  }
}
